module.exports={
  "name": "@yeuai/botscript",
  "version": "1.8.0",
  "description": "A text-based scripting language and bot engine for Conversational User Interfaces (CUI)",
  "main": "dist/engine",
  "scripts": {
    "test": "mocha",
    "build": "rimraf dist && tsc",
    "build:web": "npx gulp"
  },
  "repository": {
    "type": "git",
    "url": "git+https://github.com/yeuai/botscript.git"
  },
  "keywords": [
    "chatbot",
    "dialog",
    "engine"
  ],
  "author": "vunb",
  "license": "MIT",
  "bugs": {
    "url": "https://github.com/yeuai/botscript/issues"
  },
  "homepage": "https://github.com/yeuai/botscript#readme",
  "devDependencies": {
    "@types/chai": "^4.3.3",
    "@types/debug": "^4.1.7",
    "@types/jexl": "^2.3.1",
    "@types/mocha": "^10.0.1",
    "@types/node": "^18.11.9",
    "axios-mock-adapter": "^1.21.2",
    "browserify": "^17.0.0",
    "chai": "^4.3.6",
    "coffee-script": "^1.12.7",
    "gulp": "^4.0.2",
    "gulp-cli": "^2.3.0",
    "gulp-rename": "^2.0.0",
    "gulp-sourcemaps": "^3.0.0",
    "gulp-terser": "^2.1.0",
    "gulp-typescript": "^6.0.0-alpha.1",
    "mocha": "^10.2.0",
    "rimraf": "^5.0.1",
    "ts-node": "^10.9.1",
    "tsify": "^5.0.4",
    "tslint": "^5.20.1",
    "typescript": "^4.8.2",
    "vinyl-buffer": "^1.0.1",
    "vinyl-source-stream": "^2.0.0"
  },
  "dependencies": {
    "axios": "^0.21.4",
    "debug": "^4.3.4",
    "handlebars": "^4.7.7",
    "jexl": "^2.3.0",
    "openai": "^4.6.0",
    "rxjs": "^7.8.1",
    "vm2": "3.9.5",
    "xregexp": "^4.4.1",
    "xstate": "^4.33.6"
  },
  "files": [
    "dist",
    "src",
    "LICENSE",
    "README.md"
  ],
  "publishConfig": {
    "access": "public"
  }
}
