module.exports={
  "name": "coffee-script",
  "description": "Unfancy JavaScript",
  "keywords": [
    "javascript",
    "language",
    "coffeescript",
    "compiler"
  ],
  "author": "Jeremy Ashkenas",
  "version": "1.12.7",
  "license": "MIT",
  "engines": {
    "node": ">=0.8.0"
  },
  "directories": {
    "lib": "./lib/coffee-script"
  },
  "main": "./lib/coffee-script/coffee-script",
  "bin": {
    "coffee": "./bin/coffee",
    "cake": "./bin/cake"
  },
  "files": [
    "bin",
    "lib",
    "register.js",
    "repl.js"
  ],
  "scripts": {
    "test": "node ./bin/cake test",
    "test-harmony": "node --harmony ./bin/cake test"
  },
  "homepage": "http://coffeescript.org",
  "bugs": "https://github.com/jashkenas/coffeescript/issues",
  "repository": {
    "type": "git",
    "url": "git://github.com/jashkenas/coffeescript.git"
  },
  "devDependencies": {
    "docco": "~0.7.0",
    "google-closure-compiler-js": "^20170626.0.0",
    "highlight.js": "~9.12.0",
    "jison": ">=0.4.17",
    "markdown-it": "^8.3.1",
    "underscore": "~1.8.3"
  }
}
